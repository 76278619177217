import React, { useState, useEffect } from "react";
import get from "lodash/get";
import PropTypes from "prop-types";
import { Button } from "antd";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { graphql } from "gatsby";

import { Video } from "../../components/Media";
import { ContentBlock, TextBlock } from "../../components/Blocks";
import seoData from "../../components/SEO/data";
import Pages from "../../components/Pages";
import { removeEmptyKeys, removePreloader } from "../../helpers";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import { Share } from "../../widgets";
import { showMore } from "../../constants";
import { useThemeContext } from "../../components/Layout/ThemeContext";
import { ALL_PROJECTS_VIDEOS, ALL_PROJECTS_VIDEOS_MAX_UPDATE } from "../../queries/queries.graphql";

export const query = graphql`
	query projectsVideos($pagePath: String!) {
		hasura {
			...AllProjectsVideos
			...PageSettings
		}
	}
`;

export default function AllProjectsVideosPage({ data }) {
	const { theme } = useThemeContext();

	const url = "videos";
	const limit = 4;

	const [videoLibrary, setVideoLibrary] = useState(get(data, "hasura.v_serafim_projects_videos", []));
	const [loading, setLoading] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const [offset, setOffset] = useState(4);
	const seo = get(data, "hasura.page_settings[0]", {});

	const maxUpdated = new Date(get(data, "hasura.v_serafim_projects_videos_aggregate.aggregate.max.updated_at", new Date()));

	const { loading: maxUpdateLoading, data: maxUpdateData, error: maxUpdateError } =
		useQuery(ALL_PROJECTS_VIDEOS_MAX_UPDATE, { fetchPolicy: "no-cache" });

	const [
		loadFIrstChunkMediaVideos,
		{
			called: firstChunkmediaVideoCalled,
			loading: firstChuncmediaVideoLoading,
			error: firstChuncmediaVideoError,
			data: firstChuncmediaVideoData,
		},
	] = useLazyQuery(
		ALL_PROJECTS_VIDEOS,
		{
			variables: {
				offset: 0,
				limit,
			},
			fetchPolicy: "no-cache",
		},
	);

	const [
		loadMediaVideos,
		{
			called: mediaVideoCalled,
			loading: mediaVideoLoading,
			error: mediaVideoError,
			data: mediaVideoData,
		},
	] = useLazyQuery(
		ALL_PROJECTS_VIDEOS,
		{
			variables: {
				offset,
				limit,
			},
			fetchPolicy: "no-cache",
		},
	);

	useEffect(() => {
		if (maxUpdateData && !maxUpdateError) {
			const currentMaxUpdated = new Date(get(maxUpdateData, "v_serafim_projects_videos_aggregate.aggregate.max.updated_at", new Date()));

			if (+currentMaxUpdated !== +maxUpdated) {
				loadFIrstChunkMediaVideos();
			} else {
				removePreloader();
			}
		} else if (maxUpdateError) {
			console.error("Invalid load loadMediaVideosMaxUpdate", { maxUpdateError });
			removePreloader();
		}

		removePreloader(!maxUpdateLoading || maxUpdateError);
	}, [maxUpdateLoading]);

	useEffect(() => {
		if (firstChunkmediaVideoCalled && firstChuncmediaVideoData && !firstChuncmediaVideoError) {
			const data = get(firstChuncmediaVideoData, "v_serafim_projects_videos", []);

			setVideoLibrary(data);
		} else if (mediaVideoError) {
			console.error("Invalid load mediaVideoError", { firstChuncmediaVideoData });
		}
	}, [firstChuncmediaVideoLoading]);

	useEffect(() => {
		if (mediaVideoCalled && mediaVideoData && !mediaVideoError) {
			const data = get(mediaVideoData, "v_serafim_projects_videos", []);

			setIsDisabled(!data.length || data.length !== 4);

			setVideoLibrary([...videoLibrary, ...data]);

			setLoading(false);

			setOffset(offset + 4);
		} else if (mediaVideoError) {
			console.error("Invalid load mediaVideoError", { mediaVideoData });
		}
	}, [mediaVideoLoading]);

	function handleLoad() {
		setLoading(true);

		loadMediaVideos({
			variables: {
				limit,
				offset,
			},
		});
	}

	const uniqueVideos = videoLibrary.reduce((result, item) => {
		if (!result.some(res => res.id === item.id)) {
			result.push(item);
		}

		return result;
	}, []);

	return (
		<Pages entity={seo || get(seoData, "videos_page", {})} url={url}>
			<ContentBlock key={"breadcrumbs"}>
				<div className="container">
					<Breadcrumbs
						currentLabel={"Видеотека"}
						pageContext={
							{
								item: {
									title_full: "Гуманитарные проекты",
									slug: "serafim-project",
								},
							}
						}
					/>
				</div>
			</ContentBlock>
			<ContentBlock mount={uniqueVideos && uniqueVideos.length} key={"main-content"}>
				<div className={"container"}>
					<TextBlock title={"Все видео"} className={"pb-0"} />
					<div className={"row"}>
						{uniqueVideos.map(({ title_full, src, slug }, idx) => (
							<Video
								key={`video-libraryy-item-${idx}`}
								videoSrcURL={src}
								slug={slug}
								videoTitle={title_full}
								className={"col-12 col-md-6"}
							/>
						))}
					</div>
					<div className="row justify-content-center mb-5">
						<Button
							disabled={isDisabled}
							loading={loading}
							onClick={() => handleLoad()}
							className={"d-noscript-none"}
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.text,
								lineHeight: theme.lineHeight.text,
								color: theme.color.body,
								backgroundColor: theme.color.text,
							})}
						>
							{showMore}
						</Button>
					</div>
				</div>
			</ContentBlock>
			<Share url={url} pageTitleShort={"Все видео"} />
		</Pages>
	);
}

AllProjectsVideosPage.propTypes = {
	data: PropTypes.object,
};
